import '../Footer/footer.css';
import LogoBlanco from '../../img/logotipo_blanco_frasan_green_mexico.png';
import WhatsappFooter from './Boton_WA/whatsapp-footer';
import RedesFooter from '../Footer/Boton-redes-footer/redes-footer';
import footerFB from '../../img/facebook.png';
import footerIG from '../../img/instagram.png';
import footerYB from '../../img/youtube.png';
import footerIN from '../../img/linkedin.png';


/* Función para llamar el año actual */
function fechaActual() {
    const year = new Date().getFullYear();
    return year;
}

function Footer() {
    return(

        <footer>

            <div className="col_1">

                <img src={LogoBlanco} alt="" />

                <a href="#contacto">CONTACTO</a>

                <a href="#home">NOSOTROS</a>

                <a href="">PRIVACIDAD</a>

            </div>

            <div className="col_2">

                <WhatsappFooter />

                <h2>Frasan Green de México {fechaActual()}</h2>

            </div>

            <div className="col_3">

                <RedesFooter 
                imagen={footerFB}
                texto='Face Book'
                href='https://www.facebook.com/profile.php?id=61564966953271' />

                <RedesFooter
                imagen={footerIG}
                texto='Instagram'
                href='https://www.instagram.com/frasangreendemexico/' />

                <RedesFooter
                imagen={footerYB}
                texto='Youtube' />

                <RedesFooter
                imagen={footerIN}
                texto='Linkedin' />

            </div>

        </footer>

    );
}

export default Footer;