import '../card/card.css';

function Card ({ cambio, imagen, titulo, parrafo }) {
    return(

        <div className={`card ${cambio}`}>

            <div className="imagen"><img src={imagen} alt="" /></div>

            <div className="contenido">

                <h2>{titulo}</h2>

                <div className="separador"></div>

                <p>{parrafo}</p>

            </div>

        </div>

    );
}

export default Card;