import '../whatsapp/whatsapp.css';
import LogoWhatsapp from '../../img/logo-wa.png';

function Whatsapp() {
    return(

        <div id='contacto' className="whatsapp" style={{ scrollMarginTop: '150px' }}>

            <div className="content">

                <div className="content-wrap">

                    <div className="title-1"><h2>CONTACTANOS</h2></div>

                    <div className="title-2"><h2>ESTAMOS PARA SERVIRTE</h2></div>

                    <div className="logo"><img className='imagen-wa' src={LogoWhatsapp} alt="" /></div>

                </div>

            </div>

        </div>

    );
}

export default Whatsapp;