import '../Li/linav.css';

function LiNav( { texto, link, click } ) {
    return(

        <li className="nav-item" onClick={click}>
            
            <a href={link}>{texto}</a>
            
        </li>

    );
}

export default LiNav;