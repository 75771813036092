import '../Header/header.css';
import RedesHead from '../Header/Botonera/redes-compononente-header';
import fbLogo from '../../img/facebook.png';
import igLogo from '../../img/instagram.png';
import ytLogo from '../../img/youtube.png';
import inLogo from '../../img/linkedin.png';
import BrandLogo from '../../img/logotipo_frasan_green_mexico.png';
import BrandLogoBlanco from '../../img/logotipo_blanco_frasan_green_mexico.png'; 
import LiNav from '../Header/Li/linav';

/*========= Hipervinculos Botonera Redes Top Header =========*/
function FB () {
    window.open('https://www.facebook.com/profile.php?id=61564966953271', '_blank');
}

function IG () {
    window.open('https://www.instagram.com/frasangreendemexico/', '_blank');
}

/* ========= Funcion Menu Hamburguesa ========= */
function HamOn() {
    const ham = document.querySelector('.bottom-head .right nav');
    ham.classList.add('nav-on');
}

function HamOff() {
    const closeHam = document.querySelector('.bottom-head .right nav');
    closeHam.classList.remove('nav-on');
}

/* Funciones de Hipervinculo del Nav */

function Servicios() {  
    const serv = document.getElementById('servicios');
    serv.scrollIntoView();

    const closeHam = document.querySelector('.bottom-head .right nav');
    closeHam.classList.remove('nav-on');
}

function Contacto() {
    const cont = document.getElementById('contacto');
    cont.style.scrollMarginTop = '150px';
    cont.scrollIntoView();

    const closeHam = document.querySelector('.bottom-head .right nav');
    closeHam.classList.remove('nav-on');
}


function Header () {
    return (

        <div className="header">

            <div className="top-head">

                <div className="left">

                    <RedesHead logo={fbLogo} click={FB} />

                    <RedesHead logo={igLogo} click={IG} />

                    <RedesHead logo={ytLogo} />

                    <RedesHead logo={inLogo} />

                </div>

                <div className="right">
                    <p>contacto@frasangreenmexico.com</p>
                </div>

            </div>

            <div className="bottom-head">

                <div className="left">
                    <a href="/"><img src={BrandLogo} alt="" /></a>
                </div>

                <div className="right">

                    <div className="ham"><svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16" onClick={HamOn}>
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                    </svg></div>

                    <nav>

                        <img className='logo-movil-nav' src={BrandLogoBlanco} alt="" />

                        <LiNav 
                        texto='Servicios'
                        click={Servicios} />

                        <LiNav 
                        texto='Contacto'
                        click={Contacto} />

                        {/* <LiNav texto='Blog' /> */}

                        <button className='cerrar-menu' onClick={HamOff}>Cerrar Menú</button>

                    </nav>

                </div>

            </div>

        </div>

    );
}

export default Header;