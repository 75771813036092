import '../Boton_WA/whatsapp-footer.css';
import LogoWa from '../../../img/logo-wa.png';

function WhatsappFooter() {
    return(

        <button>

            <img src={LogoWa} alt="" />

            <div className="black-layer"></div>

        </button>

    );
}

export default WhatsappFooter;