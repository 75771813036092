import '../contact-form/contact-form.css';
import LogoForm from '../../img/logotipo_frasan_green_mexico_ 2.png';

function ContactForm() {
    return(

        <form action="enviar.php" method="post" className="form-contact">

            <div className="form-wrap">


                <div className="left"><img src={LogoForm} alt="" /></div>

                <div className="right">

                    <input type="text" name="name" id="name" className="name" placeholder="Nombre" required />

                    <input type="text" name="mail" id="mail" className="email" placeholder="Correo Electronico" required />

                    <textarea name="message" className="message" placeholder="Mensaje" id="message" required></textarea>
                    
                    <button type="submit" class="enviar">Enviar Comentarios</button>

                </div>

            </div>

        </form>

    );
}

export default ContactForm;