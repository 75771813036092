import '../Botonera/botonera.css';



function RedesHead ( {logo, idBoton, click} ) {
    return (

        <div id={idBoton} className="redes-head" onClick={click}>

            <img src={logo} alt="" />
            
        </div>

    );
}

export default RedesHead;