import './App.css';
import Header from './Componentes/Header/header';
import Footer from './Componentes/Footer/footer';
import ImagenNosotros from './img/nosotros.jpg';
import Card from './Componentes/card/card';
import ImgRecoleccion from './img/recoleccion.jpg';
import ImgTraslado from './img/trasalado.jpg';
import ImgDisposicion from './img/diposcicion.jpg';
import ImgOperacion from './img/operacion.jpg';
import Whatsapp from './Componentes/whatsapp/whatsapp';
import ContactForm from './Componentes/contact-form/contact-form';
import Slider from './Componentes/slider/slider';

function App() {
  return (
    <div className="App">
      
      <Header /> 

          <Slider />

      <main>


          {/* Sección NOSOTROS */}
          <section id='nosotros' className='nosotros'>

            <div className="contenido">

                <h2>NOSOTROS</h2>

                <p>Somos una compañía 100% mexicana con más de 10 años de trayectoria en la recolección y  el manejo de residuos sólidos urbanos. Estamos comprometidos con proteger el medio ambiente con la finalidad de dar  cumplimiento al marco legal en materia ambiental, enfrentando los desafíos con un enfoque  basado en riesgos.</p>

                <div className="imagen">
                    <img src={ImagenNosotros} alt="" />
                </div>

            </div>

          </section>

          {/* Sección Servicios */}
          <section id='servicios' className='servicios' style={{ scrollMarginTop: '150px' }}>

            <div className='titulo-servicios'>

              <h3>Nuestros</h3>

              <h2>SERVICIOS</h2>

            </div>

            {/* Cards */}
            <Card
            imagen = {ImgRecoleccion}
            titulo = 'RECOLECCIÓN'
            parrafo = 'En Frasan Green, nos especializamos en la recolección de residuos sólidos urbanos  generados por el sector industrial y empresarial. Nos encargamos de los residuos resultantes  de las actividades diarias, como la eliminación de materiales, productos consumidos,  envases, embalajes y cualquier otro desecho generado dentro de sus instalaciones. Ofrecemos soluciones personalizadas para garantizar una gestión eficiente y responsable de  sus residuos.' />

            <Card
            cambio = 'cambio'
            imagen = {ImgTraslado}
            titulo = 'TRASLADO'
            parrafo = 'En Frasan Green, contamos con una flota de vehículos especializados para garantizar un  transporte seguro y eficiente de los residuos sólidos urbanos. Nuestros compactadores de 21  yardas cúbicas, equipados con un sistema de carga trasera Winch, están montados sobre  chasis International 4300, lo que nos permite ofrecer un servicio de alta capacidad y  rendimiento. Ponemos a disposición una variedad de contenedores con capacidades de 1.5, 3 y 4.5 m³,  compatibles con nuestro sistema de carga trasera. Nos comprometemos a brindar un  transporte confiable, siempre priorizando la seguridad de las operaciones y el cuidado del  medio ambiente en cada recolección.' />

            <Card
            imagen = {ImgDisposicion}
            titulo = 'DISPOSICIÓN FINAL'
            parrafo = 'En Frasan Green, aseguramos que los residuos sólidos urbanos (RSU) sean gestionados de  manera responsable hasta su disposición final en sitios que cumplen con todos los requisitos  normativos. Nos aliamos con rellenos sanitarios certificados que utilizan métodos avanzados  de compactación y control ambiental, garantizando un proceso seguro y sustentable para  minimizar los impactos en el medio ambiente.

            En Frasan Green, nos enfocamos en garantizar que cada residuo, tanto RSU como residuos  de manejo especial (RME), sea gestionado de manera segura y responsable, contribuyendo al  cuidado del medio ambiente y la sustentabilidad a largo plazo.' />

            <Card
            cambio = 'cambio'
            imagen = {ImgOperacion}
            titulo = 'CONSTRUCCIÓN Y OPERACIÓN DE RELLENOS SANITARIOS'
            parrafo = 'En Frasan Green, apoyamos a empresas y gobiernos en la construcción y operación de  rellenos sanitarios bajo los más altos estándares de ingeniería y normatividad ambiental.  Nuestro equipo de expertos asesora en el diseño y la implementación de infraestructuras  eficientes y seguras para la disposición final de residuos. Nuestra experiencia nos permite colaborar en proyectos que garantizan un manejo  responsable de los residuos, asegurando que los rellenos sanitarios operen de manera  segura, minimizando el impacto ambiental y cumpliendo con todas las normativas vigentes.' />

          </section>

          {/* Contacto Whatsapp */}
          <Whatsapp />

          {/* Formulario de Contacto */}
          <ContactForm />

      </main>

      <Footer />

    </div>
  );
}

export default App;
