import '../Boton-redes-footer/redes-footer.css';


function RedesFooter({imagen, texto, href}) {
    return(

        <a href={href} className='redes-footer' target='_blank'>

            <img className='imagen-redes-footer' src={imagen} alt="" />

            <div className="pleca">
                {texto}
            </div>

        </a>

    );
}

export default RedesFooter;