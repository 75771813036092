// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import '../slider/slider.css';

// import required modules
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';

import Slide_1 from '../../img/slide_1.jpg';
import Slide_2 from '../../img/slide_2.jpg';
import Slide_3 from '../../img/slide_3.jpg';

function Slider() {

  return (
    <div id='home' style={{ scrollMarginTop:'150px' }}>
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        effect="fade"
        modules={[Autoplay, Pagination, Navigation, EffectFade]}
        className="mySwiper"
      >
        <SwiperSlide><img src={Slide_1} alt="" /></SwiperSlide>
        <SwiperSlide><img src={Slide_2} alt="" /></SwiperSlide>
        <SwiperSlide><img src={Slide_3} alt="" /></SwiperSlide>
      </Swiper>
    </>
    </div>
  );
  
}

export default Slider;
